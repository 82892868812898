

.testimonio-grid{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 7rem;
    align-content: center;
    width:100%;
}

.testimonio-box{
    width: 35rem;
    height: 100%;
    min-height: 13rem;
    padding: 1.8rem;
    background-color: #3604abe3;
        color: white;
    border-radius: 6px;

}

.testimonio-title{
    margin-bottom: 1rem;
}

.testimonio-text{
    quotes: """ """;
}

.moreLess{
    font-size: 1.2rem;
    cursor: pointer;
}

.moreLess_text{
    font-weight: 600;
    cursor: pointer;
}