.selectLg{
    border-radius: 0.5rem;
    height: 2rem;
    background-color: #2e2e2e;
    text-transform: uppercase;
    width: auto;
    color: #ffff;
  
}

.selectLi{
   display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

@media screen and (max-width: 940px) {
.selectLg{
    
    width: auto;
    font-size: 1.2rem;
}
.selectLi{
    margin: 0 auto;
    width: 100%;
    justify-content: flex-end;
    margin-right: 3rem;
}
}

