.container-f{
  width:100%;
}

.logoFooter {
  width: 13.75rem;
  height: 11.875rem;
  margin: 2rem auto 0 auto;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-icons {
  margin: 0 auto;
}

.footer-icons a{
  text-decoration: none;
}


.footer-icons img {
  width: 3rem;
  /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25)); */
  border: solid 0.18rem #3908f2;
  border-radius: 50%;
  padding: 0.5rem;
  margin: 0.5rem;
  z-index: 2;
    position: relative;
}


.footer-icons img:hover{
animation: bounce  1s infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100%{
    transform: translateY(-0.2rem);
  }
}

.footer-text {
  text-align: center;
  margin-top: 2rem;
}

.footer-text h4 {
  font-weight: 700;
  font-size: 1.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.footer-text span {
  margin-right: 0.75rem;
}

.container-info {
  display: flex;
  justify-content: space-around;
  width: 37.5rem;
  flex-direction: row;
  margin: 3rem auto;
}

.bottom-footer {
  background: var(--purple-color);

  position: absolute;
  width: 100%;
  height: 6rem;
  left: 0rem;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.bottom-footer p {
  font-size: "Nunito";
  font-size: 0.9rem;
  padding: 1rem;
  font-weight: 400;
  color: whitesmoke;
}


.lineFooter{
  position: absolute;
  width: 52.375rem;
  height: 46.4375rem;
  transform: translate(3.8125rem, -42.6875rem);
}

.footer-terms{
  display: flex;
  gap: 1.2rem;
  justify-content: center;
 
  
}

.terms{
  text-decoration: none;
  color: #343434;
}


@media only screen and (max-width: 599px) {

.lineFooter{
  width: 35.375rem;
  height: 46.4375rem;
  transform: translate(-0.1875rem, -42.6875rem);
}

}

