.empresas-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .empresas-container img {
    filter: grayscale(1);
    object-fit: contain;
    cursor: pointer;
    width: 10rem;
    height: 10rem;

    
  }
  
  .empresas-container img:hover {
    filter: none;
    transform: scale(1.3);
    transition: transform 0.4s;
  }
  

.slider{
    height:15.625rem;
    margin: auto;
    position: relative;
    width: 100%;
    display: flex;
    place-items: center;
    overflow: hidden;
}



.slide{
    align-items: center;
    padding: 0.9375rem;
   
    display: flex;
    perspective: 6.25rem;

}

.slideBtn{
    font-size: 1.8rem;
    color: #607d8b;
    background: none;
    border: none;
    cursor: pointer;
}